
// **********************************************************************
// SK - LANGUAGE
// **********************************************************************
export const sk = {
    language: 'SK',
    title: 'Pestovateľská pálenica Velčice',
    title1: 'Pálenica',
    title2: 'Velčice',
    slogan_1: 'Sila prírody',
    slogan_2: ' v každom dúšku',
    slogan_banner: 'Vstúpte do sveta autentických chutí a objavte kúzlo našej pestovateľskej pálenice',
    slogan_4: 'Spojte sa s prírodou, obklopte sa kameňom s Marvi Stoner',
    intro: 'Vítame vás na stránkach našej pestovateľskej pálenici, kde sa tradícia stretáva s inováciou a kvalita je na prvom mieste. Naša pálenica je miestom, kde sa s láskou a odbornosťou venujeme výrobe destilátov z ovocia. S hrdosťou ponúkame produkty, ktoré vznikajú kombináciou tradičných postupov a moderných technológií.',
    intro_text: 'Proces výroby kvasu',
    intro_text_2: 'Remeslo, ktoré si vychutnáte',
    company_info: 'Informácie o pestovateľskej pálenici',
    page_intro: 'Realizácia kamenných obkladov, dlažieb a murív',

    // ----------------------------------------------------------------------------------------------------------------

    gallery: 'Galéria',
    gallery_text: 'Galéria fotografií',

    about_us: 'O nás',

    // ============================================================================================================
    // KONTAKTNE INFORMACIE
    // ============================================================================================================
    company_name: 'Obchodný názov',
    company_street: 'Ulica',
    company_town: 'Ulica',
    company_psc: 'PSČ',
    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Meno a priezvisko',
    street: 'Ulica',
    street_number: 'číslo',
    psc: 'PSČ',
    town: 'Mesto',
    email: 'E-mail',
    email_error: 'Nesprávny formát e-mailovej adresy',
    mobil: 'Mobil',
    phone: 'Tel.číslo',
    state: 'Štát',
    logo: 'Logo',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    DPH: 'DPH',
    call_us: 'Volajte',
    contact_form: 'Kontaktný formulár',
    contact_form_text: 'Vyplňte náš jednoduchý kontaktný formulár a my sa Vám čoskoro ozveme.',
    message_question: 'Chcete sa na niečo opýtať? Napíšte nám.',
    message_text: 'Text správy',
    message_email: 'Vaša emailová adresa',
    message_mobil: 'Vaše telefónne číslo',
    message_send_ok: 'Správa bola úspešne odoslaná',
    message_send_err: 'Pro odosielaní nastala chyba. Skúste požiadavku opakovať.',
    slovakia: 'Slovensko',

    // ============================================================================================================
    // OTHERS
    // ============================================================================================================
    continue: 'Pokračovať',
    back: 'Späť',
    close: 'Zavrieť',
    required: 'Údaje označené červenou sú povinné',
    number: 'Číslo',
    active: 'Aktívny',
    label: 'Názov',
    db_error: 'Pri zápise nastala chyba. Skúste požiadavku opakovať',
    new: 'Nový',
    add: 'Pridať',
    ok: 'OK',
    cancel: 'Zrušiť',
    save: 'Uložiť',
    save_changes: 'Uložiť zmeny',
    edit: 'Upraviť',
    search: 'Hľadať',
    send: 'Odoslať',
    send_request: 'Odoslať požiadavku',
    saving: '...',
    subscribe: 'Odoberať',
    error: 'Chyba',
    goto_app: 'Prejsť do aplikácie',
    demo: 'Vyskúšať zadarmo',
    captcha_error: 'Chýba potvrdenie - nie som robot',
    send_error: 'Pri odosielaní požiadavky nastala chyba. Skúste požiadavku opakovať',
    back_to_home: 'Späť na hlavnú stránku',
    order: 'Objednávka',
    order_text: 'Zakúpenie licencie',
    home: 'Domov',
    menu: 'Menu',
    required_data: 'Údaje označené * sú povinné',
    watch: 'Pozrieť',


    // ============================================================================================================
    // GDPR + COOKIES + VOP
    // ============================================================================================================
    gdpr: 'Ochrana osobných údajov',
    cookies_rules: 'Zásady používania súborov cookies',
    sell_docs: 'Všeobecné obchodné podmienky',


    // ============================================================================================================
    // MENU
    // ============================================================================================================
    products: 'Produkty',
    products_text: 'Výroba a predaj rohoží',
    product_obklady: 'Kamenné obklady',
    product_dlažby: 'Kamenné dlažby',
    product_muriva: 'Kamenné murivá',
    reference: 'Referencie',
    reference_text: 'Naši klienti',
    contact: 'Kontakt',
    contact_text: 'Kontaktujte nás',
    contact_data: 'Kontaktné údaje',
    services: 'Služby',
    our_services: 'Čo ponúkame',
    more_info: 'Viac informácii',
    write: 'Napište nám',
    contact_us: 'Kontaktujte nás',
    contact_us_text: 'Máte otázky alebo potrebujete viac informácií? Neváhajte nás kontaktovať! Sme tu, aby sme vám pomohli.',
    contact_us_text_2: 'Ak máte akékoľvek otázky, pripomienky alebo jednoducho len chcete povedať „ahoj“, neváhajte nás kontaktovať.',


    // ============================================================================================================
    // FOOTER
    // ============================================================================================================
    copyright: 'Copyright',
    footer_text: 'Ponúkame komplexné služby spracovania vášho ovocia na kvalitné destiláty. Naším cieľom je poskytnúť vám odborné poradenstvo a profesionálne služby pri každom kroku výroby, od prípravy kvasu až po balenie hotového destilátu.',

    // PROCES VYROBY KVASU

    intro_kvas: 'Proces výroby kvasu',
    intro_kvas_1: 'Drvenie',
    intro_kvas_2: 'Kvasenie',
    intro_kvas_3: 'Miešanie a kontrola kvasu',

    destile: 'Destilácia',
}

