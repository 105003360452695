import React, { useEffect, useState, useRef, useCallback } from 'react';
import './App.css';
import './items.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Main } from './main';
import { MenuTop, Menu, Footer } from './items';
import { Debug, GetLang, LoadLanguage, LoadTheme, SaveLanguage } from './functions';
import { styles } from './styles.js';
import { Backdrop, useMediaQuery } from '@mui/material';
import { Products } from './products.js';
import { Gallery } from './gallery.js';
import { Contact } from './contact.js';
import { ProductsDlazby } from './products_dlazby.js';
import { ProductsObklady } from './products_obklady.js';
import { ProductsMuriva } from './products_muriva.js';
import { ProductsKupelne } from './products_kupelne.js';
import { AboutUs } from './about_us.js';
import { GalleryPhotos } from './gallery_photos.js';

function App() {
  const navigate = useNavigate();

  const logo = require('./react/app/logo.png');
  const loadingScreen = document.getElementById('loading-screen');

  const [isLoading, setLoading] = useState(true);
  const [redraw, setRedraw] = useState(true);

  const [theme, setTheme] = useState(LoadTheme());
  const [language, setLanguage] = useState(LoadLanguage());
  const [menuID, setMenuID] = useState(0);
  const [company, setCompany] = useState(false);
  const [texts, setTexts] = useState(false);
  const [page, setPage] = useState(false);
  const [pageItems, setPageItems] = useState(false);
  const [contactPage, setContactPage] = useState(false);
  const [contactPageItems, setContactPageItems] = useState(false);

  // SCREEN SIZES
  const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
  const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
  const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

  // SCREEN SETTINGS
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  var color = global.themes[theme];
  var lang = GetLang(language);

  var running = false;
  var focused = false;

  useEffect(() => {

    if (running == false) {
      db_get();

      running = true;
    }

    if (loadingScreen) {
      loadingScreen.style.display = 'none';
    }

    function handleResize() {
      setWidth(window.innerWidth);
      //setHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);


    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  const handleFocus = (e) => {
    if (focused == false) {
      focused = true;
      Debug('FOCUS');

      db_get();
    }
  }

  const handleBlur = (e) => {
    focused = false;
    Debug('---> BLUR');
  }

  /* *******************************************************************************
      NAČÍTANIE UDAJOV O SPOLOCNOSTI
  ******************************************************************************* */

  const db_get = async () => {

    try {
      const response = await fetch(
        global.db_url + 'web_data', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: 1
        })
      })

      const json = await response.json();
      Debug(json);
      if (json.ok > 0) {
        setCompany(json.company);
        setTexts(json.texts);
        setPage(json.main_page);
        setPageItems(json.main_page_items);
        setContactPage(json.contact_page);
        setContactPageItems(json.contact_page_items);
        setRedraw(!redraw);
      }

      setTimeout(() => {
        setLoading(false);
      }, 500);


    } catch (error) {
      console.log(error);
    }
  }


  const MenuTopPress = (typ, value) => {
    if (typ == 0) {
      // ZMENA JAZYKA
      setLanguage(value);
      SaveLanguage(value);
    }
    if (typ == 1) {
      // FAREBNEJ TEMY
      setTheme(value);
    }

  }

  const MenuPress = (typ, value) => {
    Debug(typ);
    if (typ == 1) {
      // MENU PRESS
      ChangeLink(value);
    }
    if (typ == 2) {
      // ZMENA JAZYKA
      setLanguage(value);
      SaveLanguage(value);
    }
    if (typ == 3) {
      // FAREBNEJ TEMY
      setTheme(value);
    }
  }

  const FooterPress = (typ, id) => {
    // BOL STLAČENÝ LINK      
    if (typ == 0) {
      ChangeLink(id);
    }
  }

  const ChangeLink = (id) => {
    Debug('ID: ' + id);
    if (id == 0) {
      // HOME
      navigate('/');
    }
    if (id == 2) {
      // galéria
      navigate('/gallery');
    }
    if (id == 3) {
      // kontakt
      navigate('/contact');
    }
    if (id == 20) {
      // o nás
      navigate('/about_us');
    }
  }

  const PageResult = (typ, id) => {
    if (typ == 0) {
      // KAZDA STRANKA PO NAČITANÍ VRATI SVOJ INDEX -> PREPNUTIE MENU
      // 99 => NONE
      setMenuID(id);
    }
    if (typ == 1) {
      // Stlačený link
      ChangeLink(id);
    }
  }

  return (
    <div className="App" style={{ ...styles.BlockCenter, backgroundColor: color.white, WebkitUserSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', userSelect: 'none' }}>

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />

      <Helmet>
        <title>{global.title}</title>
        <meta name="description" content="Marvi Stoner - kamenné obklady a dlažby" />
      </Helmet>


      <Backdrop open={isLoading} transitionDuration={{ enter: 0, exit: 1000 }} sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} onClick={null} style={{ backgroundColor: '#000000', width: width, height: height }}>
        <div style={{ ...styles.Block, width: width, height: height, backgroundColor: global.theme_white, borderRadius: 50 }}>
          <img src={logo} style={{ width: 300, height: 300 }}></img>
        </div>
      </Backdrop>

      {isLoading == false ?
        <div style={{ ...styles.BlockCenter }}>

          {isSmall ? null :
            <MenuTop texts={texts} company={company} isSmall={isSmall} theme={theme} lang={lang} language={language} func={MenuTopPress.bind(this)} />
          }

          <Menu texts={texts} id={menuID} company={company} isSmall={isSmall} theme={theme} lang={lang} language={language} func={MenuPress.bind(this)} />

          <Routes>
            <Route path="/" element={<Main texts={texts} page={page} pageItems={pageItems} company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
            {/*
          <Route path="/products" element={<Products company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />          
          */}
            <Route path="/gallery" element={<GalleryPhotos texts={texts} company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
            <Route path="/contact" element={<Contact page={contactPage} pageItems={contactPageItems} texts={texts} company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
            <Route path="/about_us" element={<AboutUs texts={texts} company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
            {/*
                <Route path="/product/:id" element={<Product theme={theme} lang={lang} language={language} func={PageResult.bind(this)}/>} />
          */}
            <Route path="*" element={<Main texts={texts} pageItems={pageItems} company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          </Routes>

          <Footer texts={texts} textID={7} company={company} theme={theme} lang={lang} language={language} func={FooterPress.bind(this)} />

        </div >
        : null}
    </div >
  );
}

export default App;
