/*
*
* =================================================================================================================

    WEB PAGE DESIGNER

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    preview    -> len náhľad

* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';


import { Avatar, DialogTabs, DialogYesNo, EmptyList, FormSelect, FormSpace, FormText, Icon, Loading, ShowError, ShowOK, TextInput, FormYesNo, TextLine, DeleteButton } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, FormControlLabel, IconButton, Paper, Slider, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa1, fa2, faAlignCenter, faAlignLeft, faAlignRight, faArrowLeft, faArrowRight, faBold, faItalic, faLayerGroup, faPen, faPlusCircle, faQuestion, faSave, faT, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetImageLink, GetLeftPosition, GetPages, UniqueID } from './functions';

export const WebPageDesigner = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];


    // DATA LIST
    const [items, setItems] = useState(false);
    const [overID, setOverID] = useState(-1);

    // PAGE SETTINGS
    const [pageEnabled, setPageEnabled] = useState(true);
    const [backgroundTyp, setBackgroundTyp] = useState(0);
    const [backgroundColor1, setBackgroundColor1] = useState('#FFFFFF');
    const [backgroundColor2, setBackgroundColor2] = useState('#E0E0E0');
    const [paddingTop, setPaddingTop] = useState(0);
    const [paddingBottom, setPaddingBottom] = useState(20);
    const [borderRadius, setBorderRadius] = useState(0);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width;

    // CONSTANTS
    const tabHeight = props.preview == true ? 0 : 40;
    const menuHeight = 50;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            setItems(props.pageItems);

            setPageEnabled(props.page.enabled == false ? false : true);
            setBackgroundTyp(props.page.gradient == false ? 0 : 1);
            setBackgroundColor1(props.page.background_color_1);
            setBackgroundColor2(props.page.background_color_2);
            setBorderRadius(props.page.border_radius);
            setPaddingTop(props.page.padding_top);
            setPaddingBottom(props.page.padding_bottom);


            running = true;
        }

    }, []);


    return (

        <div style={{
            ...styles.BlockCenter,
            marginTop: 10
        }}
        >
            <div style={{
                ...styles.BlockCenter,
                background: backgroundTyp == 0 ? 'linear-gradient(180deg, ' + backgroundColor1 + ' 0%, ' + backgroundColor1 + ' 100%)' : 'linear-gradient(180deg, ' + backgroundColor1 + ' 0%, ' + backgroundColor2 + ' 100%)',
                borderRadius: borderRadius,
            }}>
                <div style={{ ...styles.Block, paddingTop: paddingTop, paddingBottom: paddingBottom }}>

                    {items != false ? items.map((item, index) => (
                        <div key={item.id} style={{ ...styles.Block }}>
                            <PageItemRender design data={item} width={dialogWidth} theme={props.theme} />
                        </div>
                    )) : null}
                </div>

            </div>
        </div>
    );
}

export const PageItemRender = (props) => {

    /*
     <PageItemRender design data={storeData} width={dialogWidth} theme={props.theme} />
 
    */
    const no_image = require('./react/app/no_image.jpg');
    const data = props.data;
    const gallerySize = data.width;
    //const lang = props.lang;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let color = global.themes[props.theme];

    useEffect(() => {
        
        return () => {
        };

    }, []);

    const DivideText = (txt) => {
        var result = txt.split('\n');

        return result;
    }

    const RenderText = (props) => {
        let data = props.data;

        return (
            DivideText(data.text).map((item, index) => (
                item == '' ?
                    <div key={index} style={{ ...styles.Block, height: data.text_size }}></div>
                    :
                    <p key={index} style={{ ...styles.TextTiny, fontSize: data.text_size, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', color: data.text_color, fontWeight: data.text_style == 1 ? '600' : '400', fontStyle: data.text_style == 2 ? 'italic' : undefined }}>{item}</p>
            ))
        )
    }

    return (
        <div style={{
            ...styles.Block,
            paddingTop: data.padding_top,
            paddingBottom: data.padding_bottom,
            borderRadius: data.border_radius,
            background: data.gradient == false ? 'linear-gradient(180deg, ' + data.background_color_1 + ' 0%, ' + data.background_color_1 + ' 100%)' : 'linear-gradient(180deg, ' + data.background_color_1 + ' 0%, ' + data.background_color_2 + ' 100%)'
        }}>
            <div style={{
                ...styles.Block,
                width: data.max_width == 0 ? data.typ == 5 ? '100%' : '96%' : data.max_width,
                maxWidth: data.max_width == 0 ? undefined : data.max_width,
                alignItems: data.h_align == 0 ? 'flex-start' : data.h_align == 1 ? 'center' : 'flex-end',
            }}>
                {data.typ == 1 ?
                    // *********************************************************************************************************
                    // LABEL
                    // *********************************************************************************************************
                    <p style={{ ...styles.TextTiny, fontSize: data.label_size, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', color: data.label_color, fontWeight: data.label_style == 1 ? '600' : '400', fontStyle: data.label_style == 2 ? 'italic' : undefined }}>{data.label}</p>
                    : null}

                {data.typ == 2 ?
                    // *********************************************************************************************************
                    // TEXT
                    // *********************************************************************************************************
                    <RenderText data={data} />
                    : null}

                {data.typ == 3 ?
                    // *********************************************************************************************************
                    // HTML
                    // *********************************************************************************************************
                    <div style={{ ...styles.TextSmall, color: data.text_color, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', fontSize: data.text_size }} dangerouslySetInnerHTML={{ __html: data.html }} />
                    : null}


                {data.typ == 4 ?
                    // *********************************************************************************************************
                    // IMAGE
                    // *********************************************************************************************************
                    <>
                        <img src={data.image == '' ? no_image : GetImageLink(data.image)} style={{ width: '100%', height: '100%', maxWidth: data.width, maxHeight: data.height, objectFit: 'contain', borderRadius: data.border_radius }}></img>
                        {data.text != '' ?
                            <>
                                <RenderText data={data} />
                            </>
                            : null}
                    </>
                    : null}

                {data.typ == 5 ?
                    // *********************************************************************************************************
                    // BANNER
                    // *********************************************************************************************************
                    <img src={data.image == '' ? no_image : GetImageLink(data.image)} style={{ width: '100%', height: '100%', /*maxHeight: data.height, */objectFit: 'contain' }}></img>
                    : null}

                {data.typ == 6 ?
                    // *********************************************************************************************************
                    // OBRAZOK + TEXT
                    // *********************************************************************************************************
                    <div style={{ ...styles.BlockRow, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {data.image_position == 0 ?
                            <div style={{ ...styles.BlockLeft, width: data.width + 10 }}>
                                <img src={data.image == '' ? no_image : GetImageLink(data.image)} style={{ width: '100%', height: '100%', maxWidth: data.width, maxHeight: data.height, objectFit: 'contain', borderRadius: data.border_radius }}></img>
                            </div>
                            : null}
                        <div style={{ ...styles.Block, alignItems: data.h_align == 0 ? 'flex-start' : data.h_align == 1 ? 'center' : 'flex-end', width: props.width - data.width - 20, marginLeft: 10, marginRight: 10, justifyContent: 'center', display: 'flex' }}>
                            {data.label != '' ?
                                <p style={{ ...styles.TextTiny, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', marginBottom: 10, fontSize: data.label_size, color: data.label_color, fontWeight: data.label_style == 1 ? '600' : '400', fontStyle: data.label_style == 2 ? 'italic' : undefined }}>{data.label}</p>
                                : null}
                            {data.text != '' ?
                                <RenderText data={data} />
                                : null}
                            {data.button_text != '' ?
                                <a href={props.design == true || data.link == '' ? undefined : data.link} target='_blank' onClick={() => Debug()} style={{ ...data.button_style == 0 ? styles.ButtonThemed : styles.ButtonOutlined, textDecoration: 'none', minWidth: 160, width: undefined, backgroundColor: data.button_style == 0 ? data.button_color : undefined, borderColor: data.button_style == 1 ? data.button_color : undefined, color: data.button_text_color, marginTop: 20 }}>
                                    <div style={{ ...styles.Block, height: 34 }}>
                                        {data.button_text}
                                    </div>
                                </a>
                                : null}
                        </div>
                        {data.image_position == 2 ?
                            <div style={{ ...styles.BlockRight, width: data.width + 10 }}>
                                <img src={data.image == '' ? no_image : GetImageLink(data.image)} style={{ width: '100%', height: '100%', maxWidth: data.width, maxHeight: data.height, objectFit: 'contain' }}></img>
                            </div>
                            : null}
                    </div>
                    : null}

                {data.typ == 8 ?
                    // *********************************************************************************************************
                    // CIARA
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block, minHeight: data.text_size, backgroundColor: data.text_color, marginTop: 10, marginBottom: 10 }}></div>
                    : null}

                {data.typ == 9 ?
                    // *********************************************************************************************************
                    // MEDZERA
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block, minHeight: data.text_size }}></div>
                    : null}

                {data.typ == 10 ?
                    // *********************************************************************************************************
                    // ODKAZ
                    // *********************************************************************************************************
                    <a href={props.design == true || data.link == '' ? undefined : data.link} target='_blank' onClick={() => Debug()} style={{ ...data.button_style == 0 ? styles.ButtonThemed : styles.ButtonOutlined, textDecoration: 'none', minWidth: 160, width: undefined, backgroundColor: data.button_style == 0 ? data.button_color : undefined, borderColor: data.button_style == 1 ? data.button_color : undefined, color: data.button_text_color, marginTop: 20 }}>
                        <div style={{ ...styles.Block, height: 34 }}>
                            {data.button_text}
                        </div>
                    </a>
                    : null}

                {data.typ == 11 ?
                    // *********************************************************************************************************
                    // PRODUKT
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.BlockLeft, width: data.width + 10 }}>
                            <img src={data.image == '' ? no_image : GetImageLink(data.image)} style={{ width: '100%', height: '100%', maxWidth: data.width, maxHeight: data.height, objectFit: 'contain', borderRadius: data.border_radius }}></img>
                        </div>
                        <div style={{ ...styles.Block, marginTop: 10, alignItems: data.h_align == 0 ? 'flex-start' : data.h_align == 1 ? 'center' : 'flex-end' }}>
                            {data.label != '' ?
                                <p style={{ ...styles.TextTiny, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', marginBottom: 6, fontSize: data.label_size, color: data.label_color, fontWeight: data.label_style == 1 ? '600' : '400', fontStyle: data.label_style == 2 ? 'italic' : undefined }}>{data.label}</p>
                                : null}
                            {data.text != '' ?
                                <RenderText data={data} />
                                : null}
                            {data.button_text != '' ?
                                <a href={props.design == true || data.link == '' ? undefined : data.link} target='_blank' onClick={() => Debug()} style={{ ...data.button_style == 0 ? styles.ButtonThemed : styles.ButtonOutlined, textDecoration: 'none', minWidth: 160, width: undefined, backgroundColor: data.button_style == 0 ? data.button_color : undefined, borderColor: data.button_style == 1 ? data.button_color : undefined, color: data.button_text_color, marginTop: 20 }}>
                                    <div style={{ ...styles.Block, height: 34 }}>
                                        {data.button_text}
                                    </div>
                                </a>
                                : null}
                        </div>
                    </div>
                    : null}

                {data.typ == 12 ?
                    // *********************************************************************************************************
                    // TIP / UPOZORNENIE
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block, borderLeft: data.width + 'px solid ' + data.button_text_color, backgroundColor: data.button_color, paddingTop: 10, paddingBottom: 10, borderRadius: data.border_radius }}>
                        <div style={{ ...styles.Block, alignItems: data.h_align == 0 ? 'flex-start' : data.h_align == 1 ? 'center' : 'flex-end' }}>
                            {data.label != '' ?
                                <p style={{ ...styles.TextTiny, marginLeft: 10, marginRight: 10, textAlign: data.h_align == 0 ? 'left' : data.h_align == 1 ? 'center' : 'right', marginBottom: 6, fontSize: data.label_size, color: data.label_color, fontWeight: data.label_style == 1 ? '600' : '400', fontStyle: data.label_style == 2 ? 'italic' : undefined }}>{data.label}</p>
                                : null}
                            {data.text != '' ?
                                <div style={{ marginLeft: 10, marginRight: 10, marginTop: 16 }}>
                                    <RenderText data={data} />
                                </div>
                                : null}
                        </div>
                    </div>
                    : null}

            </div>
        </div >
    );
}
