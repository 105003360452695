//import img_background from './react/app/background.jpg';

global.themes = [
    {
        id: 0,
        test: '#0000FF',
        //background_image: img_background,
        back: '#F0F0F0',
        back_blue: '#F7F8FD',

        top_bar: 'linear-gradient(to right,#FFFFFF,#D8D8D8)', // farba TOP MENU

        // TOP MENU
        top_menu_color: '#000000',
        button_palette: 'linear-gradient(to right,#FF0000,#0000FF)',

        // MAIN MENU
        menu_color: 'linear-gradient(to right,#AEC0D0,#7D93A7)', // farba TOP MENU
        menu_color_: '#7D93A7', // farba TOP MENU
        menu_text: '#0C1025',
        menu_background: '#101531',

        // FOOTER
        footer_background: '#0C1A25',

        // THEMED COLORS
        lighteen: '#D9E2E9',
        lighter: '#A1B6C8',
        light: '#6C859B',
        medium: '#445564',
        dark: '#0C1A25',
        darker: '#000000',

        lighteen_blue: '#E2ECFF',
        lighter_blue: '#92BBEC',
        light_blue: '#2A8EFF',
        blue: '#205CCA',
        dark_blue: '#003BA4',
        darker_blue: '#041A47',

        lighteen_red: '#FFE4E2',
        lighter_red: '#F99891',
        light_red: '#F77268',
        red: '#F44336',
        dark_red: '#C3170B',
        darker_red: '#801000',
        red_text: '#B92F00',

        lighteen_green: '#E2FFE3',
        light_green: '#A6FA9D',
        green: '#46F436',
        dark_green: '#18BB18',
        darker_green: '#15A115',
        darkest_green: '#107010',

        yellow: '#FFDD7A',
        badge: '#A12E28',
        placeholder: '#cccccc',

        grayer: '#f7f7f7',
        gray: '#eeeeee',
        light_gray: '#dedede',
        xxgray: '#CCCCCC',
        middle_gray: '#A0A0A0',
        medium_gray: '#888888',
        dark_gray: '#666666',
        darker_gray: '#252525',

        lighteen_violet: '#E9E2FF',
        light_violet: '#FFAAFF',
        violet: '#BB00BB',
        orange: 'orange',
        dark_orange: '#D47300',
        light_cyan: '#80A0FF',
        cyan: 'cyan',
        dark_cyan: '#1080B0',
        brown: 'brown',

        white: '#FFFFFF',
        black: '#000000',

        // BUTTONS
        button_light: '#92C2E7',
        button_medium: '#226AA3',
        button_dark: '#103450',

        // NEAKTIVNA POLOZKA - farba pozadia
        disabled: '#D0D0D0',
        disabled_border: '4px solid #FF0000',

        // default border
        border: '1px solid #dedede',

        text_dark: '#333C52',
    },
    {
        id: 1,
        test: '#FF0000',
    }
]

