
// **********************************************************************
// CZ - LANGUAGE
// **********************************************************************
export const cz = {
    language: 'SK',
    title: 'Marvi Stoner',
    title1: 'Marvi',
    title2: 'Stoner',
    slogan_1: 'Kamenné',
    slogan_2: 'obklady',
    slogan_3: 'dlažby',
    slogan_banner: 'Spojte sílu přírody s naší kreativitou',
    slogan_4: 'Spojte se s přírodou, obklopte se kamenem s Marvi Stoner',
    intro: 'Vítejte na online expozici našich realizací kamenných okladů, dlažeb a zdiv – místě, kde se potýká tradiční elegance s moderní estetikou. Kamenné oklady se staly nepostradatelným prvkem v architektonickém designu, zdobí domy, veřejné budovy a komerční prostory po celém světě.',
    intro_text: 'V naší nabídce naleznete široký výběr kamenářských prací',
    intro_text_2: 'Kamenné obklady a dlažby vytvářejí unikátní a nadčasový vzhled. Vyrobeno z mramoru, žuly, vápence či břidlice, jsou mimořádně odolné a trvanlivé, což z nich činí ideální materiál pro interiéry i exteriéry.',
    company_info: 'Informace o společnosti Marvi Stoner',
    page_intro: 'Realizace kamenných obkladů, dlažeb a zdiv',

    // ============================================================================================================
    // MAIN PAGE - TEXTY
    // ============================================================================================================    

    obklady: 'Obklady',
    obklady_note: 'různé druhy kamenných obkladů, od klasických vápenců až po luxusní mramory a mozaiky',

    obklady_intro_text: 'S kamennými obklady můžete transformovat každý prostor na místo plné osobitosti a půvabu.',
    obklady_intro_sub_text: 'Bez ohledu na to, zda plánujete renovovat váš domov, přidat šmrnc vašemu obchodnímu prostoru nebo vytvořit elegantní exteriér vašeho domu, kamenné obklady jsou ideálním řešením pro dosažení stylového a trvanlivého povrchu.',
    obklady_variant_1: 'Interiérové obklady',
    obklady_variant_1_text: 'Používáno v obytných prostorách, koupelnách, kuchyních, obývácích.',
    obklady_variant_2: 'Exteriérové obklady',
    obklady_variant_2_text: 'Odolné vůči povětrnostním vlivům, vhodné pro fasády, zahradní zdi, ploty.',
    obklady_variant_3: 'Dekorativní obklady',
    obklady_variant_3_text: 'Používáno k vytvoření uměleckých prvků, mozaiky, stěny s výrazným vizuálním efektem.',

    // ------------------------------------------------ -------------------------------------------------- --------------

    dlazby: 'Dlažby',
    dlazby_note: 'dlažby od tradičních keramických až po moderní betonové a přírodní kamenové dlažby',

    dlazby_intro_text: 'Dlažby představují základní stavební prvek pro každý interiér či exteriér, a naše nabídka vám poskytuje široký výběr možností.',
    dlazby_intro_sub_text: 'Náš sortiment zahrnuje různé druhy dlažeb, od tradičních až po moderní přírodní kamenové dlažby. Jsme přesvědčeni, že každá dlažba má své jedinečné vlastnosti a specifické využití, a proto vám rádi poskytneme odborné poradenství a pomůžeme vám vybrat tu správnou dlažbu pro vaše projekty.',
    dlazby_variant_1: 'Formatovaná dlažba',
    dlazby_variant_1_text: 'Vytvořeného z přírodního kamene, který je nařezaný do pravidelných tvarů a velikostí',
    dlazby_variant_2: 'Neformátovaná dlažby',
    dlazby_variant_2_text: 'Dlažba, která se ponechává v nepravidelných tvarech a velikostech',
    dlazby_variant_3: 'Dlažební kostky',
    dlazby_variant_3_text: 'Malé bloky kamene používané k vytváření pevných a trvanlivých povrchů, jako jsou chodníky, příjezdové cesty, náměstí nebo historické ulice',

    // ------------------------------------------------ -------------------------------------------------- --------------

    muriva: 'Zdiva',
    muriva_note: 'stavební konstrukce vytvořené z kamenů, uložené a spojené nasucho, nebo pomocí malty',

    muriva_intro_text: 'Kamenná zdiva, tradiční stavební prvek používaný po staletí, jsou obdivuhodným spojením estetiky, funkčnosti a trvanlivosti.',
    muriva_intro_sub_text: 'Tyto zdi se často nacházejí v historických stavbách, zahradách, vinicích či při budování teras a plotů. Kamenná zdiva nejenže dodávají prostředí rustikální a autentický vzhled, ale zároveň poskytují vynikající odolnost vůči povětrnostním vlivům a dlouhověkost. ',
    muriva_variant_1: 'Nosné zdivo',
    muriva_variant_1_text: 'Používáno v nosných konstrukcích budov, kde je důležitá vysoká pevnost a stabilita.',
    muriva_variant_2: 'Dekorativní zdivo',
    muriva_variant_2_text: 'Používáno pro ozdobné účely, jako jsou fasády, zahradní zdi, a architektonické detaily.',
    muriva_variant_3: 'Oplocení',
    muriva_variant_3_text: 'Zdivo používané pro výstavbu plotů a oplocení kolem pozemků a budov.',
    muriva_variant_4: 'Zahradní zdivo',
    muriva_variant_4_text: 'Zdivo používané v zahradních konstrukcích, jako jsou opěrné zdi, terasy a okrasné prvky.',
    muriva_variant_footer_text: 'Kamenná zdiva nejenže dodávají prostředí rustikální a autentický vzhled, ale zároveň poskytují vynikající odolnost vůči povětrnostním vlivům a dlouhověkost. Jsou symbolem lidské dovednosti a harmonického propojení s přírodou.',

    // ------------------------------------------------ -------------------------------------------------- --------------

    others: 'Ostatní',
    others_note: 'realizace, které nezapadají do žádných konkrétních kategorií',

    // ------------------------------------------------ -------------------------------------------------- --------------

    badroom: 'Obklady koupelen',
    badroom_note: 'luxusní a nadčasový prvek, který dodává prostoru elegantní a přírodní vzhled',

    bathroom_intro_text: 'Kamenné obklady koupelen představují luxusní a nadčasový prvek, který dodává prostoru elegantní a přírodní vzhled. ',
    bathroom_intro_sub_text: 'Přinášíme vám široký výběr kamenných obkladů, které dotváří luxusní a přírodní vzhled vašich koupelen.',

    bathroom_variant_1: 'Stěnové obklady',
    bathroom_variant_1_text: 'Kamenné obklady používané na stěny koupelny, které dodávají prostorům eleganci a trvanlivost.',
    bathroom_variant_2: 'Podlahové obklady',
    bathroom_variant_2_text: 'Odolné kamenné dlaždice vhodné pro koupelnové podlahy, které odolávají vlhkosti a opotřebení.',
    bathroom_variant_3: 'Dekorativní prvky',
    bathroom_variant_3_text: 'Speciálně upravené kamenné prvky, jako jsou hranaté lišty, bordury nebo obkladové detaily, které přidávají koupelně speciální vzhled.',
    bathroom_variant_footer_text: 'Ať už preferujete moderní minimalistický styl nebo tradiční eleganci, naše kamenné obklady poskytnou vaší koupelně jedinečný a zvláštní charakter. Prozkoumejte naši kolekci a začněte plánovat transformaci vaší koupelny ještě dnes!',

    // ------------------------------------------------ -------------------------------------------------- --------------

    gallery: 'Galerie',
    gallery_text: 'Vítejte v naší galerii – realizaci našich prací kamenných obkladů, dlažeb a zdiv.',
    gallery_categories: 'Další kategorie realizaci',

    stone_gallery: 'Galerie našich realizací',
    stone_gallery_note: 'Na této stránce naleznete výběr našich nejlepších projektů, které demonstrují krásu, eleganci a trvanlivost přírodního kamene.',
    stone_gallery_text: 'Věříme, že vás naše práce inspirují a že jste najdete spoustu nápadů pro váš následující projekt. Máte-li jakékoli dotazy, nebo byste se chtěli dozvědět více o našich službách - kontaktujte nás.',

    stone_gallery_obklady: 'Kamenné obklady',
    stone_gallery_obklady_text: 'Nechte se inspirovat našimi realizacemi.',

    stone_gallery_dlažby: 'Kamenné dlažby',
    stone_gallery_dlažby_text: 'galerii realizací kamenných obkladů',

    stone_gallery_murivá: 'Kamenná zdiva',
    stone_gallery_muriva_text: 'galerii realizací kamenných obkladů',

    stone_gallery_ostatne: 'Jiné realizace',
    stone_gallery_ostatne_text: 'galerii realizací kamenných obkladů',


    // ================================================ ================================================== ==========
    // VYHODY KAMENNÝCH OBKLADŮ
    // ================================================ ================================================== ==========
    stone_advantage: 'Výhody použití kamene',
    stone_advantage_text: 'Kamenné obklady a dlažby mají řadu výhod, které z nich dělají atraktivní volbu pro různé prostory',

    stone_advantage_1: 'Odolnost a trvanlivost',
    stone_advantage_text_1: 'Kamenné materiály jako mramor, žula a vápenec jsou mimořádně pevné a odolné vůči opotřebení, což zaručuje jejich dlouhou životnost.',

    stone_advantage_2: 'Estetická hodnota',
    stone_advantage_text_2: 'Přírodní kámen přináší do prostoru elegantní a nadčasový vzhled, který se hodí do různých designových stylů',

    stone_advantage_3: 'Jednoduchá údržba',
    stone_advantage_text_3: 'Kamenné obklady a dlažby se snadno čistí a udržují, což z nich dělá praktickou volbu pro rušné domácnosti.',

    stone_advantage_4: 'Ekologická volba',
    stone_advantage_text_4: 'Přírodní kámen je ekologický materiál, který nevyžaduje mnoho zpracování, a je recyklovatelný.',


    // ================================================ ================================================== ==========
    // PRODUKTY
    // ================================================ ================================================== ==========
    stone_products: 'Produkty',
    stone_products_text: 'Naše nabídka zahrnuje široký výběr kamenných materiálů - od tradičních vápenců a mramoru až po moderní mozaiky a betonové dlaždice. Jsme tady, abychom vám pomohli vybrat ten správný materiál, který zvýrazní styl vašeho interiéru nebo exteriéru a zároveň bude odolný a snadno udržitelný.',
    stone_products_obklady_dlazby: 'Obklady a dlažby',
    stone_products_obklady_dlazby_text: 'Jsme specialisté na přírodní kámen a nabízíme široký sortiment produktů, které dodají vašemu domovu, zahradě nebo komerčnímu prostoru nezaměnitelný styl a eleganci.',
    stone_products_text2: 'V naší nabídce kamenných obkladů a dlažeb naleznete široký výběr materiálů, které uspokojí i ty nejnáročnější požadavky na design a funkčnost. Kontaktujte nás ještě dnes a začněte svou cestu k úžasným kamenným povrchům!',
    stone_products_offer: 'Široká nabídka',

    // ================================================ ================================================== ==========
    // O NÁS
    // ================================================ ================================================== ==========
    about_us: 'O nás',
    about_us_label: 'Rádi bychom se vám představili!',
    about_us_sub_label: 'Naše společnost se zabývá profesionální instalací kamenných obkladů a dlažeb, přičemž sdružujeme léta zkušeností s vášní pro detail a kvalitu.',
    about_us_text_1: 'V našem světě kamenných obkladů se snoubí vášeň s odbornou dovedností, přičemž každý kámen vypráví svůj jedinečný příběh o kvalitě a eleganci. S 25letými zkušenostmi jsme hrdí na to, že jsme lídrem v oblasti realizace kamenných obkladů, které dotváří interiéry a exteriéry s osobitým stylem a charakterem.',
    about_us_text_2: 'Naše společnost se vyvinula z hledání dokonalosti v oblasti kamenných obkladů. Od svých skromných začátků jsme se stali renomovaným partnerem pro ty, kteří hledají jedinečná řešení pro své projekty. Jsme zavázáni k poskytování nejvyšší kvality výrobků a služeb, které přesahují očekávání našich zákazníků.',
    about_us_text_3: 'Každý člen našeho týmu je specialista ve své oblasti a sdílí vášeň a oddanost k naší společné vizi. Jsme tady, abychom vám poskytli odborné poradenství, kreativní řešení a profesionální podporu během celé cesty vašeho projektu.',

    // ================================================ ================================================== ==========
    // KONTAKTNÍ INFORMACE
    // ================================================ ================================================== ==========
    company_name: 'Obchodní název',
    company_street: 'Ulice',
    company_town: 'Ulice',
    company_psc: 'PSČ',
    jméno: 'Jméno',
    surname: 'Příjmení',
    name_surname: 'Jméno a příjmení',
    street: 'Ulice',
    street_number: 'číslo',
    psc: 'PSČ',
    town: 'Město',
    email: 'E-mail',
    email_error: 'Nesprávný formát e-mailové adresy',
    mobil: 'Mobil',
    phone: 'Tel.číslo',
    state: 'Stát',
    logo: 'Logo',
    ico: 'IČ',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    DPH: 'DPH',
    call_us: 'Volejte',
    contact_form: 'Kontaktní formulář',
    contact_form_text: 'Vyplňte náš jednoduchý kontaktní formulář a my se Vám brzy ozveme.',
    message_question: 'Chcete se na něco zeptat? Napište nám.',
    message_text: 'Text zprávy',
    message_email: 'Vaše emailová adresa',
    message_mobil: 'Vaše telefonní číslo',
    message_send_ok: 'Zpráva byla úspěšně odeslána',
    message_send_err: 'Pro odesílání nastala chyba. Zkuste požadavek opakovat.',
    slovakia: 'Slovensko',

    // ================================================ ================================================== ==========
    // OTHERS
    // ================================================ ================================================== ==========
    continue: 'Pokračovat',
    back: 'Zpět',
    close: 'Zavřít',
    required: 'Údaje označené červeně jsou povinné',
    number: 'Číslo',
    active: 'Aktivní',
    label: 'Název',
    db_error: 'Při zápisu nastala chyba. Zkuste požadavek opakovat',
    new: 'Nový',
    add: 'Přidat',
    ok: 'OK',
    cancel: 'Storno',
    save: 'Uložit',
    save_changes: 'Uložit změny',
    edit: 'Upravit',
    search: 'Hledat',
    send: 'Odeslat',
    send_request: 'Odeslat požadavek',
    saving: '...',
    subscribe: 'Odebírat',
    error: 'Chyba',
    goto_app: 'Přejít do aplikace',
    demo: 'Vyzkoušet zdarma',
    captcha_error: 'Chybí potvrzení - nejsem robot',
    send_error: 'Při odesílání požadavku nastala chyba. Zkuste požadavek opakovat',
    back_to_home: 'Zpět na hlavní stránku',
    order: 'Objednávka',
    order_text: 'Zakoupení licence',
    home: 'Domů',
    menu: 'Menu',
    required_data: 'Údaje označené * jsou povinné',
    watch: 'Podívat',

    // ================================================ ================================================== ==========
    // GDPR + COOKIES + VOP
    // ================================================ ================================================== ==========
    gdpr: 'Ochrana osobních údajů',
    cookies_rules: 'Zásady používání souborů cookies',
    sell_docs: 'Všeobecné obchodní podmínky',


    // ================================================ ================================================== ==========
    // MENU
    // ================================================ ================================================== ==========
    products: 'Produkty',
    products_text: 'Výroba a prodej rohoží',
    product_obklady: 'Kamenné obklady',
    product_dlažby: 'Kamenné dlažby',
    product_muriva: 'Kamenná zdiva',
    reference: 'Reference',
    reference_text: 'Naši klienti',
    contact: 'Kontakt',
    contact_text: 'Kontaktujte nás',
    contact_data: 'Kontaktní údaje',
    services: 'Služby',
    our_services: 'Co nabízíme',
    more_info: 'Více informací',
    write: 'Napište nám',
    contact_us: 'Kontaktujte nás',
    contact_us_text: 'Pokud vás naše práce oslovily a měli byste zájem o realizaci, nebo o spolupráci, neváhejte nás kontaktovat.',
    contact_us_text_2: 'Máte-li jakékoli dotazy, připomínky nebo prostě jen chcete říct „ahoj“, neváhejte nás kontaktovat.',


    // ================================================ ================================================== ==========
    // FOOTER
    // ================================================ ================================================== ==========
    copyright: 'Copyright',
    footer_text: 'Ať už hledáte klasický elegantní vzhled nebo moderní design, jsme tady, abychom vám pomohli najít to pravé řešení pro vaše projekty. Náš tým odborníků vám rád poskytne odborné poradenství a pomoc při výběru nejvhodnějších kamenných okladů nebo dlažeb.',

}

