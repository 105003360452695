import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { styles } from './styles.js';
import { Debug, GetLang, LoadTheme } from './functions.js';
import './globals.js';
import './themes.js';
import { LoadLanguage } from './functions.js';
import { BannerSlider, ContainerBasic, ContainerBox, ContainerDark, ContainerText, Space, ContainerLight, ContainerCompare, Line, GoHome, ContainerContact, ContainerContacForm } from './items.js';
import { faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons';
import { WebPageDesigner } from './webpage_designer.js';
import GoogleMapReact from 'google-map-react';


export const Contact = (props) => {

    // IMAGES
    const image = require('./react/app/contact_us_1.jpg');

    const navigate = useNavigate();

    const lat = 48.406586;
    const lng = 18.305522;
    const pin = require('./react/app/pin.png');

    const [isBusy, setBusy] = useState(false);

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [value, setValue] = useState('');
    const test = Array.from({ length: 20 }, (_, index) => '@hour'.replace('@hour', String(index).padStart(3, '0')));

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // IMAGES
    const logo = require('./react/app/logo.png');

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    let { func } = props;
    var running = false;

    useEffect(() => {

        if (running == false) {
            // ---------------------------------------------------------------------------
            func(0, 3); // ID STRANKY -> prepnutie menu
            //GoHome(); // Scroll na začiatok stránky po načítaní
            // ---------------------------------------------------------------------------

            lang = GetLang(props.language);
            color = global.themes[props.theme];

            setTheme(props.theme);
            setLanguage(props.language);

            running = true;
        }

        return () => {
        };

    }, [props.theme, props.language, props.company]);

    const AnyReactComponent = ({ text }) => (
        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
            <img src={pin} height={40} />
            <p style={{ ...styles.TextSmall, marginTop: 12, fontWeight: 'bold', color: global.theme_dark_red }}>Pálenica</p>
        </div>
    );


    return (
        <div style={{ ...styles.BlockCenter }}>


            <div style={{ ...styles.BlockCenter, maxWidth: global.max_screen, backgroundColor: color.white }}>


                <Space />
                <ContainerBasic texts={props.texts} noButton icon={faPhone} label={lang.contact_text} textID={8} theme={theme} lang={lang} language={language} image={image} func={() => func(1, 99)} />
                <Space />
                <ContainerText texts={props.texts} center labelID={19} textID={0} subTextID={20} theme={theme} lang={lang} language={language} />

                <div style={{ height: 480, width: '100%' }}>
                    <iframe
                        src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyCVLKeXod-XpjnLLg1MEbf_wO5OFc5Wv3g&q=' + lat + ',' + lng + '&zoom=17'}
                        width="100%"
                        height="100%"
                        style={{ border: "0" }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Maps"
                    ></iframe>
                    {/*
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyCVLKeXod-XpjnLLg1MEbf_wO5OFc5Wv3g' }}
                        defaultCenter={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                        defaultZoom={17}
                    >
                        <AnyReactComponent
                            lat={lat}
                            lng={lng}
                        />
                    </GoogleMapReact>
                    */}
                </div>

                <Space />

                {props.page != false ?
                    <WebPageDesigner page={props.page} pageItems={props.pageItems} />
                    : null}

                <Space />
                <ContainerText texts={props.texts} center labelID={9} textID={0} subTextID={0} theme={theme} lang={lang} language={language} />
                <Line color={color.gray} />
                <Space />
                <ContainerContact texts={props.texts} company={props.company} center theme={theme} lang={lang} language={language} />
                <div style={{ ...styles.Block }}>

                </div>
                <Space height={100} />
            </div>

            <ContainerContacForm texts={props.texts} icon={faInfoCircle} label={lang.stone_products_obklady_dlazby} text={lang.stone_products_obklady_dlazby_text} theme={theme} lang={lang} language={language} image={image} func={() => Debug()} />

        </div>
    )
}
