import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { styles } from './styles.js';
import { Debug, GetLang, LoadTheme } from './functions.js';
import './globals.js';
import './themes.js';
import { BannerSlider, ContainerBasic, ContainerBox, ContainerDark, ContainerText, Space, ContainerCompare, Line, GoHome, ContainerDivider, ContainerPhoto, ContainerImage } from './items.js';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { PageItem, WebPageDesigner } from './webpage_designer.js';


export const Main = (props) => {

    // IMAGES
    const image = require('./react/app/image_1.jpg');
    const image_about_us = require('./react/app/about_us_1.jpg');
    const image_drvenie = require('./react/app/img_drvenie.jpg');
    const image_kvasenie = require('./react/app/img_kvasenie.jpg');
    const image_miesanie = require('./react/app/img_miesanie.jpg');
    const image_contact_us = require('./react/app/about_us_4.jpg');
    const image_destilery = require('./react/app/destilery.jpg');
    const image_video = require('./react/app/video.jpg');

    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES        
    const [play, setPlay] = useState(false);

    const test = Array.from({ length: 20 }, (_, index) => '@hour'.replace('@hour', String(index).padStart(3, '0')));

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // IMAGES
    const logo = require('./react/app/logo.png');

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    let { func } = props;

    useEffect(() => {

        // ---------------------------------------------------------------------------
        func(0, 0); // ID STRANKY -> prepnutie menu
        GoHome(); // Scroll na začiatok stránky po načítaní
        // ---------------------------------------------------------------------------

        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);

        return () => {
        };

    }, [props.theme, props.language, props.company]);



    return (
        <div style={{ ...styles.BlockCenter }}>

            <BannerSlider texts={props.texts} label1ID={1} label2ID={2} textID={0} theme={theme} lang={lang} language={language} />

            <div style={{ ...styles.BlockCenter, maxWidth: global.max_screen, backgroundColor: color.white }}>
                <Space />
                <ContainerText center texts={props.texts} labelID={16} textID={0} subTextID={3} theme={theme} lang={lang} language={language} />
                <Space />

                {/*play == false ?
                    <img onClick={() => setPlay(true)} src={image_video} style={{ width: '100%', height: '100%', maxWidth: isSmall ? width - 40 : 640, maxHeight: 360, objectFit: 'contain', borderRadius: 20, cursor: 'pointer' }}></img>
                    :
                    <video id='ending-video' autoPlay playsInline controls style={{ maxWidth: isSmall ? width - 40 : 640, maxHeight: 360, borderRadius: 20 }}>
                        <source src={global.web + '/public/uploads/video/intro.mp4'} type="video/mp4" />
                    </video>
                */}

                {props.page != false ?
                    <WebPageDesigner page={props.page} pageItems={props.pageItems} />
                    : null}

                <ContainerText center texts={props.texts} text={lang.intro_text_2} theme={theme} lang={lang} language={language} />
                <Space />

                <ContainerBasic texts={props.texts} label={lang.about_us} textID={21} theme={theme} lang={lang} language={language} icon={faInfoCircle} image={image_about_us} func={() => func(1, 20)} />
                <Space />
                <ContainerText center texts={props.texts} sub_text={lang.intro_text} theme={theme} lang={lang} language={language} />
                <Space height={10} />
                <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
                    <ContainerBox texts={props.texts} noButton label={lang.intro_kvas_1} textID={13} button={lang.watch} theme={theme} lang={lang} language={language} image={image_drvenie} func={() => func(1, 10)} />
                    <ContainerBox texts={props.texts} noButton label={lang.intro_kvas_2} textID={14} button={lang.watch} theme={theme} lang={lang} language={language} image={image_kvasenie} func={() => func(1, 11)} />
                    <ContainerBox texts={props.texts} noButton label={lang.intro_kvas_3} textID={15} button={lang.watch} theme={theme} lang={lang} language={language} image={image_miesanie} func={() => func(1, 12)} />
                </div>
                <Space height={90} />
                <Line color={color.gray} marginBottom={40} />
                <ContainerCompare texts={props.texts} label={lang.destile} textID={18} theme={theme} lang={lang} language={language} />
                <Space height={20} />
                <ContainerImage image={image_destilery} theme={theme} lang={lang} language={language} />
                <Space  height={150}/>
            </div>

            <ContainerDark texts={props.texts} label={lang.contact_text} textID={6} theme={theme} lang={lang} language={language} image={image_contact_us} func={() => func(1, 3)} />

        </div>
    )
}

/*
<ContainerLight  background={'#F5F8FF'} noBorder  label={'O Nás'} text={'Informácie o spoločnosti Marvi Stoner'} theme={theme} lang={lang} language={language} icon={faInfoCircle} image={image_square} />
<div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
    <ContainerBox label={'O Nás'} text={'Lorem ipsum dolor sit amet,aa s s d  fconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed cras ornare arcu dui vivamus arcu. In hac habitasse platea dictumst quisque sagittis purus.'} button={'pozrieť'} theme={theme} lang={lang} language={language} image={image} />
    <ContainerBox label={'O Nás'} text={'Lorem ipsum dolor sit amet,aa s s d  fconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed cras ornare arcu dui vivamus arcu. In hac habitasse platea dictumst quisque sagittis purus.'} button={'pozrieť'} theme={theme} lang={lang} language={language} image={image} />
    <ContainerBox label={'O Nás'} text={'Lorem ipsum dolor sit amet,aa s s d  fconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed cras ornare arcu dui vivamus arcu. In hac habitasse platea dictumst quisque sagittis purus.'} button={'pozrieť'} theme={theme} lang={lang} language={language} image={image} />
</div>
*/